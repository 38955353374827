import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider, useTranslation } from "react-i18next";
import "antd/dist/antd.css";
import Router from "./router";
import i18n from "./translation";
import React, {JSX, useEffect} from "react";
import AnimatedCursor from "react-animated-cursor";

const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
};


const LanguageHandler = ({ children }: { children: React.ReactNode }): JSX.Element | null => {
    const { i18n } = useTranslation();

    useEffect(() => {
        const currentLanguage = i18n.language;
        document.documentElement.dir = currentLanguage === "ar" ? "rtl" : "ltr";
    }, [i18n.language]);

    return <>{children}</>;
};


const App = () => (

    <BrowserRouter>
        {!isMobile() && (
            <AnimatedCursor
                innerSize={8}
                outerSize={35}
                innerScale={1}
                outerScale={2}
                outerAlpha={0}
                innerStyle={{
                    backgroundColor: '#301269'
                }}
                outerStyle={{
                    border: '3px solid #f27a32'
                }}
            />
        )}
        <Router />
    </BrowserRouter>
);

const rootElement = document.getElementById("root");

if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
        <I18nextProvider i18n={i18n}>
            <LanguageHandler>
                <App />
            </LanguageHandler>
        </I18nextProvider>
    );
}
