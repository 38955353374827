import React from 'react';
import { CSSProperties } from 'react';

 interface SvgIconProps {
    src: string;
    width?: string | number;
    height?: string | number;
    className?: string;
    style?: CSSProperties;
    alt?: string;
}
export const SvgIcon: React.FC<SvgIconProps> = ({
                                                    src,
                                                    width = "100%",
                                                    height = "100%",
                                                    className = "",
                                                    style = {},
                                                    alt
                                                }) => (
    <img
        src={`/img/svg/${src}`}
        alt={alt || src}
        width={width}
        height={height}
        className={`svg-icon ${className}`}
        style={{
            objectFit: 'contain',
            maxWidth: '100%',
            maxHeight: '100%',
            ...style
        }}
    />
);