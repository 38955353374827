import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import { useHistory } from "react-router-dom";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };


  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            key={src}
            aria-label={src}
        >
          <SvgIcon src={src} width="25px" height="25px" />
        </a>
    );
  };

  return (
      <>
        <FooterSection>
          <Container>
            <Row justify="space-between">
              <Col lg={10} md={10} sm={12} xs={12}>
                <Language>{t("Contact")}</Language>
                <Large to="/blog">{t("Visit our Blog")}</Large>
                <Para>
                  {t("Explore our latest updates and articles on the blog.")}
                </Para>
                <a href="mailto:support@rwadinfo.com">
                  <Chat>{t(`Let's Chat`)}</Chat>
                </a>
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                <Title>{t("Pages")}</Title>
                <Large to="/terms">{t("Terms of Service")}</Large>
                <Large to='/privacy-policy'>
                  {t("Privacy Policy")}
                </Large>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col lg={10} md={10} sm={12} xs={12}>
                <Language>{t("Address")}</Language>
                <Para>{t("Street 15, Avenue Tah")}</Para>
                <Para>{t("Ain Chock, Casablanca")}</Para>
                <Para>{t("Morocco")}</Para>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Title >{t("Language")}</Title>
                <LanguageSwitchContainer>
                  <LanguageSwitch onClick={() => handleChange("en")}>
                    <SvgIcon
                        src="united-states.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("fr")}>
                    <SvgIcon
                        src="france.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("es")}>
                    <SvgIcon
                        src="spain.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("ar")}>
                    <SvgIcon
                        src="morocco.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                </LanguageSwitchContainer>
              </Col>
            </Row>
          </Container>
        </FooterSection>
        <Extra>
          <Container border={true}>
            <Row
                justify="space-between"
                align="middle"
                style={{ paddingTop: "3rem" }}
            >
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon
                      src="logo.svg"
                      aria-label="homepage"
                      width="101px"
                      height="64px"
                  />
                </LogoContainer>
              </NavLink>
              <FooterContainer>
                <SocialLink
                    href="https://facebook.com/rwadmedia"
                    src="facebook.svg"
                />
                <SocialLink
                    href="https://github.com/"
                    src="github.svg"
                />
                <SocialLink
                    href="https://twitter.com/"
                    src="twitter.svg"
                />
                <SocialLink
                    href="https://www.linkedin.com/"
                    src="linkedin.svg"
                />
              </FooterContainer>
            </Row>
          </Container>
        </Extra>
      </>
  );
};

export default withTranslation()(Footer);
