import {useEffect, useState} from "react";
import {Row, Col, Drawer, DrawerProps} from "antd";
import {withTranslation, TFunction, useTranslation} from "react-i18next";
import { useHistory } from "react-router-dom";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const history = useHistory(); // initialize useHistory
  const [scrollToId, setScrollToId] = useState<string | null>(null);
  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');
  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      setScrollToId(id);
      history.push(`/#${id}`);
    };

    const {i18n} = useTranslation();

    useEffect(() => {
      setPlacement(i18n.language == 'ar' ? 'left' : 'right')
    }, [i18n.language]);
    return (
        <>
          <CustomNavLinkSmall onClick={() => scrollTo("about")}>
            <Span>{t("About")}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
            <Span>{t("Mission")}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => scrollTo("projects")}>
            <Span>{t("Projects")}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall
              style={{ width: "180px" }}
              onClick={() => scrollTo("contact")}
          >
            <Span>
              <Button>{t("Contact")}</Button>
            </Span>
          </CustomNavLinkSmall>
        </>
    );
  };

  useEffect(() => {
    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
        setScrollToId(null);
      }
    }
  }, [scrollToId]);

  return (
      <HeaderSection>
        <Container>
          <Row justify="space-between">
            <LogoContainer to="/" aria-label="homepage">
              <SvgIcon src="logo.svg" width="101px" height="64px" style={{ color: "#18216d" }} />
            </LogoContainer>
            <NotHidden>
              <MenuItem />
            </NotHidden>
            <Burger onClick={toggleButton}>
              <Outline />
            </Burger>
          </Row>
          <Drawer placement={placement} closable={false} open={visible} onClose={toggleButton}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <Label onClick={toggleButton}>
                <Col span={12}>
                  <Outline />
                </Col>
              </Label>
            </Col>
            <MenuItem />
          </Drawer>
        </Container>
      </HeaderSection>
  );
};

export default withTranslation()(Header);
